//孟加拉
export const bn = {
    //ন্যাভিগেশন বার
    'n1': 'হোম',
    'n2': 'অর্ডার',
    'n3': 'লেনদেন',
    'n4': 'টিম',
    'n5': 'আমার',
    // প্রম্পট ক্লাস
    't1': 'একটি ত্রুটি ঘটেছে',
    't2': 'কপি সফল',
    't3': 'তথ্য সম্পূর্ণভাবে পূরণ করুন',
    't4': 'লোড হচ্ছে...',
    't5': 'লগইন সফল',
    't6': 'জমা দেওয়ার জন্য স্কোর অবশ্যই 3-এর বেশি হতে হবে',
    't7': 'জমা সফল হয়েছে',
    't8': 'অপর্যাপ্ত ব্যালেন্স, গ্রহণ করতে অক্ষম',
    't9': 'জমা ব্যর্থ হয়েছে',
    't10': 'অনুগ্রহ করে প্রথমে ঠিকানাটি বাঁধুন, এবং আপনাকে ব্যক্তিগত কেন্দ্র পৃষ্ঠায় পুনঃনির্দেশিত করা হবে',
    't11': 'ঠিকানাটি আবদ্ধ করা হয়েছে, এটি সংশোধন করতে অনুগ্রহ করে গ্রাহক পরিষেবার সাথে যোগাযোগ করুন',
    't12': 'পরিবর্তন সফল',
    't13': 'পরিবর্তন ব্যর্থ হয়েছে',
    't14': 'আপনার পাসওয়ার্ড পরিবর্তন করতে অনুগ্রহ করে গ্রাহক পরিষেবার সাথে যোগাযোগ করুন',
    't15': 'পাসওয়ার্ড ফরম্যাট ত্রুটি',
    't16': 'প্রত্যাহার সফল',
    't17': 'সর্বোচ্চ প্রত্যাহার',
    't18': 'ন্যূনতম প্রত্যাহার',
    't19': 'দুটি পাসওয়ার্ড অসামঞ্জস্যপূর্ণ',
    't20': 'প্রম্পট',
    't21': 'অস্থায়ীভাবে লাফানো যাবে না',
    't22': 'পাসওয়ার্ড ফরম্যাট ত্রুটি',
    't23': 'নিবন্ধন সফল',
    't24': 'অনুগ্রহ করে এলাকা কোড লিখুন',
    't25': 'আর নয়',
    //নিবন্ধন করুন
    'l1': 'ভাষা',
    'l2': 'অনুগ্রহ করে আপনার অ্যাকাউন্ট নম্বর লিখুন',
    'l3': 'অনুগ্রহ করে পাসওয়ার্ড লিখুন',
    'l4': 'মনে রেখো',
    'l5': 'লগইন',
    'l6': 'রেজিস্ট্রেশনে যান',
    'l7': 'কোন অ্যাকাউন্ট নেই',
    'l8': 'মোবাইল ফোন নম্বর',
    'l9': 'ডাকনাম',
    'l10': 'পাসওয়ার্ড',
    'l11': 'পাসওয়ার্ড নিশ্চিত করুন',
    'l12': 'আমন্ত্রণ কোড',
    'l13': 'রেজিস্টার',
    'l14': 'ইতিমধ্যে একটি অ্যাকাউন্ট আছে',
    'l15': 'লগ ইন করতে যান',
    'l16': 'অনুগ্রহ করে একটি 6-16 সংখ্যার পাসওয়ার্ড লিখুন',

    // সাহায্য
    'bz1': 'সহায়তা',
    'bz2': 'FAQ',
    'bz3': 'বাণিজ্যের নিয়ম',
    'bz4': 'কীভাবে টাকা তুলতে হবে',
    'bz5': 'কিভাবে রিচার্জ করবেন',
    'bz6': '1. Amazon Review RoBoT কি?',
    'bz7': 'রিভিউ RoBoT হল একটি বিপণন এবং প্রচার সংস্থা যা সারা বিশ্বের RoBoT অনলাইন স্টোর ব্যবসায়ীদের আরও অর্ডার বিক্রি পেতে এবং RoBoT অনলাইন স্টোর ব্রাউজিং ডেটা পর্যালোচনা করতে সাহায্য করে৷ আমরা রিভিউ RoBoT, বণিক এবং ভোক্তাদের মধ্যে একটি ত্রিপক্ষীয় লাভজনক বিপণন এবং প্রচার মডেল প্রতিষ্ঠা করতে প্রতিশ্রুতিবদ্ধ। সর্বশেষ P2P ব্লকচেইন প্রযুক্তির সাথে একত্রিত হয়ে, ভোক্তা এবং ব্যবসায়ীরা দ্রুত USDT (TRC20, ERC20) এর মাধ্যমে সংযুক্ত হয়। নিবন্ধিত ব্যবহারকারীরা অর্ডারে কমিশন পেতে পারে, অন্যদিকে ব্যবসায়ীরা তাদের দোকানের বিক্রয় ডেটা বাড়াতে পারে। এটি ইন্টারনেট ব্লকচেইন মডেলের সর্বশেষ লাভ মডেল! ',
    'bz8': '2.Amazon Review RoBoT কিভাবে কাজ করে?',
    'bz9': 'রিভিউ RoBoT থেকে দৈনিক প্রতিক্রিয়া দেখায় যে যে পণ্যগুলির উন্নতির প্রয়োজন সেগুলি Amazon Review RoBoT-এ বিক্রি করা হয়৷ ব্যবহারকারীদের শুধুমাত্র অর্ডারে সহজেই ক্লিক করতে হবে, এবং সিস্টেম স্বয়ংক্রিয়ভাবে একটি অর্ডার সাবস্ক্রিপশন তৈরি করে৷ ব্যবহারকারীরা ব্লকচেইন USDT-এর মাধ্যমে অর্ডারের পরিমাণ প্রদান করে এবং দৈনিক কমিশন পান। ',
    'bz10': '3. দুটি লেনদেনে একই মুদ্রার দাম আলাদা কেন? ',
    'bz11': 'মূল্যের পার্থক্য এমন সমস্ত কারণের কারণে ঘটে যা মুদ্রার অবাধ সঞ্চালনের জন্য সহায়ক নয়, যার মধ্যে মুদ্রা স্থানান্তরের গতি, নেটওয়ার্কের অবস্থা, মুদ্রা অ্যাক্সেসের বিধিনিষেধ, বিভিন্ন অঞ্চলের লোকেদের দ্বারা মুদ্রার স্বীকৃতি, এমনকি ট্রেডিং জোড়াও অন্তর্ভুক্ত। এক্সচেঞ্জের ধরন, লেনদেন ইত্যাদি দ্বারা সরবরাহ করা হয়। অতএব, একই মুদ্রার বিভিন্ন লেনদেনে মূল্যের পার্থক্য থাকতে পারে। ',
    'bz12': '4. বিনিয়োগের মুনাফা? ',
    'bz13': 'পণ্যের দাম যত বেশি আপনি পাবেন, তত বেশি অর্ডার মুনাফা পাবেন। একই সময়ে, Amazon Review RoBoT এলোমেলোভাবে বড় কমিশন অর্ডার বিতরণ করে। ',
    'bz14': 'প্রতিদিন, সিস্টেম স্বয়ংক্রিয়ভাবে ব্যবহারকারীদের কাছে 60টি পণ্যের অর্ডার তৈরি করে এবং বিতরণ করে। ব্যবহারকারীরা USDT-এর মাধ্যমে প্রতিটি অর্ডারের জন্য অর্থপ্রদান সম্পূর্ণ করে এবং 0.6% কমিশন পায়। বড় কমিশন আদেশ এলোমেলোভাবে বরাদ্দ করা হয়. ',
    'bz15': '60টি অর্ডারের পরে USDT প্রত্যাহার করা যেতে পারে। (যদি 60টি অর্ডার সম্পূর্ণ না হয়, তবে দিনের অবশিষ্ট আদেশ পর্যন্ত সিস্টেমটি স্বয়ংক্রিয়ভাবে বন্ধ হয়ে যাবে)',
    'bz16': 'প্রতিটি অ্যাকাউন্ট শুধুমাত্র একটি USDT অ্যাকাউন্টে আবদ্ধ হতে পারে। যদি কোনো ত্রুটি ঘটে, অনুগ্রহ করে গ্রাহক পরিষেবার সাথে যোগাযোগ করুন',
    'bz17': 'দৈনিক অর্ডারের ডেলিভারি শেষ করার পরে, আপনি স্বাভাবিকভাবে টাকা তুলতে পারবেন। এটি তোলা শুরু করার 24 ঘন্টার মধ্যে পৌঁছে যাবে, এবং কোন টাকা তোলার সীমা নেই',
    'bz18': 'অনুগ্রহ করে রিচার্জ করার আগে আপনার উত্তোলনের ঠিকানা (TRC-20 সমর্থন করে) USDT ঠিকানাটি বাঁধুন। আপনি APP বা ওয়েব পৃষ্ঠায় রিচার্জ ক্লিক করতে পারেন এবং রিচার্জ করার জন্য আপনি যে ব্লকচেইন (TRC-20) ব্যবহার করছেন সেটি নির্বাচন করতে পারেন। ',
  "bz19": "মিউচুয়াল রিচার্জ বা ব্যাংক স্থানান্তরের মতো নিষিদ্ধ আচরণ রয়েছে এবং আচরণটি দূষিতভাবে দখল করার জন্য সন্দেহ করা হয়, এবং এটি পাওয়া যায় যে অ্যাকাউন্ট নম্বর দ্বারা আচরণটি শাস্তি দেওয়া হবে।",
    //মডিউল পাঠ্য
    //সাধারণ শব্দসমূহ
    'ty1': 'ঠিক আছে',
    'ty2': 'বাতিল',
    'ty3': 'জমা দিন',
    'ty4': 'ঠিকানা',
    // সামনের পৃষ্ঠা
    's1': 'ক্রমিক আয়',
    's2': 'পরিষেবা',
    's3': 'আমাদের সম্পর্কে',
    's4': 'সহায়তা',
    's5': 'পার্টনার',
    's6': 'আমার সেবা',
    //অর্ডার
    'd1': 'অসমাপ্ত',
    'd2': 'সম্পূর্ণ',
    'd3': 'এখনও কোনো ডেটা নেই',
    'd4': 'বিজোড় সংখ্যা',
    'd5': 'ট্রেডিং টাইম',
    'd6': 'অ্যামাউন্ট',
    'd7': 'বর্তমান কাজের সংখ্যা',
    'd8': 'প্রত্যাশিত রিটার্ন',
    'd9': 'আরো দরকার',
    'd10': 'রেটিং',
    //বাণিজ্য
    'j1': 'অ্যাকাউন্ট ব্যালেন্স',
    'j2': 'বাণিজ্য ভূমিকা',
    'j3': 'রিভিউ RoBoT প্রতিদিন রিভিউ RoBoT-এর ফিডব্যাক ব্যবহার করে রিভিউ RoBoT-এর দ্বারা বিক্রিত পণ্যগুলি দেখানোর জন্য যেগুলির উন্নতি প্রয়োজন। ব্যবহারকারীদের শুধুমাত্র অর্ডারে ক্লিক করতে হবে এবং সিস্টেম স্বয়ংক্রিয়ভাবে একটি অর্ডার স্বাক্ষর তৈরি করবে। ব্যবহারকারীরা USDT ব্লকচেইনের মাধ্যমে অর্ডারের পরিমাণ প্রদান করে এবং প্রতিবার 0.6% এর বেশি কমিশন পেতে পারে এবং সিস্টেম এলোমেলোভাবে বড় পুরস্কারের অর্ডার বিতরণ করে। ',
    'j4': 'মেলা শুরু করুন',
    'j5': 'আজকের উপার্জন',
    'j6': 'সংখ্যা সম্পন্ন হয়েছে',
    'j7': 'মোট কাজের সংখ্যা',
    //টীম
    'td1': 'ব্যালেন্স',
    'td2': 'কমিশন',
    'td3': 'রিসিভ',
    'td4': 'মোট লোক সংখ্যা',
    'td5': 'ব্যবহারকারী',
    'td6': 'অবদান',
    'td7': 'পরিমাণ',
    'td8': 'স্থিতি',
    //ব্যক্তিগত কেন্দ্র
    'g1': 'প্রত্যাহার',
    'g2': 'রিচার্জ',
    'g3': 'বন্ধুদের আমন্ত্রণ জানান',
    'g4': 'এখনই আমন্ত্রণ জানান',
    'g5': 'নিবন্ধন করার পর আপনার বন্ধুর প্রতিটি লাভের জন্য, আপনি কমিশনের একটি অনুপাতে পাবেন',
    'g6': 'ব্যক্তিগত তথ্য',
    'g7': 'ফান্ডের বিবরণ',
    'g8': 'রিচার্জ রেকর্ড',
    'g9': 'প্রত্যাহার রেকর্ড',
    'g10': 'ভাষা',
    'g11': 'লগ আউট',
    'g12': 'আপনি কি নিশ্চিত যে আপনি লগ আউট করতে চান?',
    //ব্যবহারকারীর তথ্য
    'yh1': 'ব্যবহারকারীর তথ্য',
    'yh2': 'পরিবর্তন করুন',
    'yh3': 'সেটিং',
    'yh4': 'টেলিফোন',
    'yh5': 'আপনি কি নিশ্চিত যে আপনি লগ আউট করতে চান?',
    'yh6': 'আসল পাসওয়ার্ড',
    'yh7': 'নতুন পাসওয়ার্ড',
    //বিস্তারিত
    'mx1': 'কমিশন কালেকশন',
    'mx2': 'লাভ',
    'mx3': 'প্রত্যাহার প্রত্যাখ্যান',
    'mx4': 'সময়',
    'mx5': 'রিচার্জ সফল',
    'mx6': 'প্রত্যাখ্যাত',
    'mx7': 'মুলতুবি',
    'mx8': 'প্রত্যাহার ঠিকানা',
    'mx9': 'উপলভ্য ব্যালেন্স',
    'mx10': 'প্রকৃত আগমন',
    'mx11': 'অনুগ্রহ করে উত্তোলনের পরিমাণ লিখুন',
    //টপ আপ
    'cz1': 'আমানত ঠিকানা শুধুমাত্র ERC20 সমর্থন করে, এবং সর্বনিম্ন জমার পরিমাণ হল 20USDT',
    'cz2': 'কপি',
    'cz3': 'ফিয়াট মুদ্রা নির্বাচন',
    'cz4': 'পেমেন্ট',
    'cz5': 'আমানতের ঠিকানা শুধুমাত্র TRC20-USDT সমর্থন করে, এবং সর্বনিম্ন জমার পরিমাণ হল 20USDT',
    'cz6': 'আমানত ঠিকানা শুধুমাত্র ERC20 সমর্থন করে, এবং সর্বনিম্ন জমার পরিমাণ হল 20USDT',
    'cz1': 'আমানত ঠিকানা শুধুমাত্র ERC20 সমর্থন করে, এবং সর্বনিম্ন জমার পরিমাণ হল 20USDT',
    'cz1': 'আমানত ঠিকানা শুধুমাত্র ERC20 সমর্থন করে, এবং সর্বনিম্ন জমার পরিমাণ হল 20USDT',
    // এলাকা
    'qy1': 'এরিয়া কোড',
    'yq1': 'বন্ধুদের আমন্ত্রণ জানান',
    'yq2': 'আমন্ত্রণ কোড',
    'yq3': 'বন্ধুদের আমন্ত্রণ জানান এবং বিশাল সুবিধা উপভোগ করুন',
    'yq4': 'যাও এবং আমন্ত্রণ করো',
}